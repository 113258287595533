<template>
  <SfButton
    v-bind="$attrs"
    v-on="$listeners"
    v-if="!displaySpinner"
    :disabled="isDisabled"
    :class="{ 'btn-disabled' : isDisabled }"
  >
    <span>
      <slot name="label1"> {{ label1 }} </slot>
    </span>
    <span class="btn-divider"> | </span>
    <span>
      <slot name="label2"> {{ label2 }} </slot>
    </span>
  </SfButton>
  <SfButton v-else :disabled="displaySpinner">
    <ASpinner />
  </SfButton>
</template>

<script>
import ASpinner from '~/theme/components/atoms/a-spinner';
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
export default {
  name: 'AButtonDoubled',
  components: {
    SfButton,
    ASpinner
  },
  props: {
    label1: {
      type: String,
      default: ''
    },
    label2: {
      type: String,
      default: ''
    },
    displaySpinner: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.btn-disabled {
  background-color: var(--c-primary);
}

.btn-divider {
  padding: 0 var(--spacer-sm);
  position: relative;
  top: -1px;
}
</style>
