<template>
  <div class="o-order-summary">
    <NoSSR>
      <div>
        <APromoCode v-if="step < 3" :should-invalidate-shipping-methods="step > 1" :is-initial-load="initialLoad" />
        <div v-if="totals">
          <SfHeading :title="$t('Products')" :level="3" class="title" />
          <div v-if="initialLoad" class="o-order-summary__spinner-container">
            <ASpinner :size="20.75" />
          </div>
          <MCollectedProductsGroup
            v-if="!initialLoad"
            :products="productsInCart"
            @click:remove="removeProductHandler"
            :disabled-updates="step > 2"
          />
        </div>
        <div v-if="step < 4 && !initialLoad" class="desktop-only">
          <MPriceSummary
            :let-magento-add-shipping-costs="step >= 3"
            :should-invalidate-shipping-methods="step > 1"
          />
        </div>
      </div>
      <template #placeholder>
        <div>
          <APromoCode :should-invalidate-shipping-methods="false" :is-initial-load="true" />
          <SfHeading :title="$t('Products')" :level="3" class="title" />
          <div v-if="initialLoad" class="o-order-summary__spinner-container">
            <ASpinner :size="20.75" />
          </div>
        </div>
      </template>
    </NoSSR>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import SfHeading from '@storefront-ui/vue/src/components/atoms/SfHeading/SfHeading.vue';
import ASpinner from '~/theme/components/atoms/a-spinner';

import MCollectedProductsGroup from '~/theme/components/molecules/m-collected-products-group';
import APromoCode from '~/theme/components/atoms/a-promo-code';
import MPriceSummary from '~/theme/components/molecules/m-price-summary';
import NoSSR from 'vue-no-ssr';
import { isServer } from '@vue-storefront/core/helpers'

export default {
  name: 'OOrderSummary',
  components: {
    SfHeading,
    MCollectedProductsGroup,
    APromoCode,
    ASpinner,
    MPriceSummary,
    NoSSR
  },
  props: {
    step: {
      type: Number,
      default: 1
    },
    initialLoad: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      totals: 'cart/getTotals',
      isCouponCode: 'cart/getAppliedCouponCode',
      productsInCart: 'cart/getCartItems',
      currentlySelectedShippingMethod: 'cart'
    }),
    hasShippingCost () {
      return !!this.totals?.find(total => total.code === 'shipping')?.value
    },
    isServer () {
      return isServer
    }
  },
  methods: {
    removeProductHandler (product) {
      this.$store.dispatch('cart/removeItem', { product });
    }
  }
};
</script>
<style lang="scss" scoped>
.o-order-summary {
  &__spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.title {
  text-align: left;
  --heading-padding: 0 0 var(--spacer-xs);
  --heading-title-margin: 0 0 var(--spacer-xl) 0;
}
::v-deep .sf-heading__title {
  --heading-title-margin: 0 0 0;
}
.property {
  margin: var(--spacer-base) 0;
}
.actions {
  &__button {
    margin: var(--spacer-sm) 0;
    &--secondary {
      text-align: left;
    }
  }
}
::v-deep .sf-collected-product {
  &__remove {
    width: calc(var(--mini-thumbnail-width, 88px) - var(--spacer-sm) * 1.3);
  }
}
</style>
<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.o-order-summary {
  @include for-mobile {
    .a-promo-code {
      display: none;
    }
  }
}
</style>
