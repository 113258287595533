<template>
  <transition name="fade">
    <div v-if="!canAddCoupon">
      <strong>
        {{ $t( 'You got the biggest discount we ever had.' ) }}
      </strong>
    </div>
    <div class="a-promo-code promo-code" v-if="canAddCoupon">
      <template>
        <div class="promo-code__toggle-coupon">
          <SfButton class="sf-button--pure" :class="{'is-disabled': isCartBusy }" @click="toggleCoupon">
            {{ $t('I want to apply coupon code') }}
          </SfButton>
        </div>
        <transition-group name="fade">
          <div v-show="isCouponVisible" key="coupon" class="promo-code__coupon">
            <SfInput
              v-model="promoCode"
              :name="name"
              :placeholder="$t('Add a discount code')"
              class="sf-input--filled promo-code__coupon-input"
              @keyup.enter="applyCoupon"
            />
            <SfButton
              class="promo-code__apply-button"
              :aria-readonly="isLoading"
              :disabled="isLoading"
              @click="applyCoupon"
            >
              {{ $t('Apply') }}
            </SfButton>
          </div>
          <div v-if="error" key="coupon-error" class="promo-code__error-message">
            {{ $t(error) }}
          </div>
        </transition-group>
      </template>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import SfInput from '@storefront-ui/vue/src/components/atoms/SfInput/SfInput.vue';
import config from 'config'
export default {
  name: 'APromoCode',
  components: {
    SfInput,
    SfButton
  },
  props: {
    name: {
      type: String,
      default: 'promoCode'
    },
    shouldInvalidateShippingMethods: {
      type: Boolean,
      default: true
    },
    isInitialLoad: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isCouponVisible: false,
      promoCode: '',
      isLoading: false,
      error: null
    };
  },
  computed: {
    ...mapGetters({
      isCouponCode: 'cart/getAppliedCouponCode',
      isCartBusy: 'cart/getCartProcessingStatus'
    }),
    canAddCoupon () {
      return config.specialOffers.canAddCoupon
    }
  },
  methods: {
    ...mapActions({
      updateShippingMethods: 'cart/updateShippingMethods'
    }),
    async toggleCoupon () {
      this.isCouponVisible = !this.isCouponVisible
    },
    async applyCoupon () {
      this.isLoading = true
      this.error = null
      const isCouponApplied = await this.$store.dispatch('cart/applyCoupon', {
        couponCode: this.promoCode,
        shouldAskForShippingMethods: this.shouldInvalidateShippingMethods
      });

      if (this.promoCode === '') {
        this.error = 'No coupon code provided.'
      } else if (isCouponApplied !== true) {
        this.error = isCouponApplied
        this.$bus.$emit('invalid-discount-code', this.promoCode)
      } else {
        if (this.shouldInvalidateShippingMethods) {
          this.updateShippingMethods(isCouponApplied.shipping_methods)
        }
        this.promoCode = ''
      }
      this.isLoading = false
    }
  },
  mounted () {
    this.toggleCoupon()
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.promo-code {
  padding: var(--spacer-xs) 0 0;
  &__toggle-coupon {
    display: flex;
    justify-content: center;
    padding: 0;
    @include for-desktop {
      padding: 0 0 var(--spacer-sm) 0;
    }
    .sf-button {
      font-size: var(--font-size--sm);
    }
  }
  &__coupon {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__coupon-input {
    --input-background: var(--c-white);
    --input-font-size: var(--font-size--sm);
    width: 70%;
  }
  &__error-message {
    color: var(--c-danger);
    font-size: var(--font-size--sm);
  }
  &__apply-button {
    width: 30%;
    padding: var(--spacer-xs);
    font-size: var(--font-size--xs);
  }
  &__remove-button {
    font-size: var(--font-size--xs);
  }
}

.is-disabled {
  color: var(--c-text-disabled);
}
</style>
