<template>
  <div
    v-click-outside="checkPersistence"
    :aria-expanded="open.toString()"
    :aria-owns="'lbox_' + _uid"
    :aria-label="label"
    role="listbox"
    :class="{
      'is-active': isActive,
      'is-selected': isSelected,
      'is-required': required,
      'is-disabled': disabled,
      'is-invalid': !valid,
    }"
    class="sf-component-select"
    @click="toggle($event)"
    @keyup.esc="closeHandler"
    @keyup.space="openHandler"
    @keyup.up="move(-1)"
    @keyup.down="move(1)"
    @keyup.enter="enter($event)"
  >
    <div style="position: relative;">
      <div
        ref="sfComponentSelect"
        v-focus
        tabindex="0"
        class="sf-component-select__selected sf-component-select-option"
        v-on="$listeners"
        v-html="html"
      ></div>
      <slot name="label">
        <div v-if="label" class="sf-component-select__label">
          {{ label }}
        </div>
      </slot>
      <slot name="icon">
        <SfChevron class="sf-component-select__chevron" />
      </slot>
      <SfOverlay
        ref="overlay"
        :visible="open"
        class="sf-component-select__overlay smartphone-only"
      />
      <transition name="sf-component-select">
        <div v-show="open" class="sf-component-select__dropdown">
          <!--  sf-component-select__option -->
          <ul
            :aria-expanded="open.toString()"
            :style="{ maxHeight }"
            class="sf-component-select__options"
          >
            <slot />
          </ul>
          <slot name="cancel">
            <SfButton
              ref="cancel"
              class="sf-component-select__cancel sf-button--full-width smartphone-only"
              @click="closeHandler"
            >
              {{ $t('Cancel') }}
            </SfButton>
          </slot>
        </div>
      </transition>
    </div>
    <div class="sf-component-select__error-message">
      <transition name="sf-fade">
        <!-- @slot Custom error message of form select -->
        <slot v-if="!valid" name="error-message" v-bind="{ errorMessage }">
          <span> {{ errorMessage }} </span>
        </slot>
      </transition>
    </div>
  </div>
</template>
<script>
import { SfComponentSelect } from '@storefront-ui/vue';

export default {
  name: 'SfComponentSelectExtend',
  mixins: [SfComponentSelect],
  watch: {
    open: {
      handler (newOpen) {
        if (newOpen) {
          document.querySelector('body').classList.add('noscroll');
          return;
        }
        document.querySelector('body').classList.remove('noscroll');
      }
    }
  }
};
</script>
