<template>
  <div class="footer-toggle-main" v-html="content" />
</template>

<script>

export default {
  name: 'OCheckoutFaqs',
  props: {
    content: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss">
.footer-toggle-main {
  --menu-item-label-color: var(--c-primary);
  margin: 0;
  padding: 0;
  .toggle-question {
    position: relative;
    padding: 15px 25px 15px 0;
    border-bottom: 1px solid var(--menu-item-label-color, var(--c-dark-variant));
    font-size: 14px;
    font-weight: normal;
    margin: 0;
    &::after {
      content: "";
      position:absolute;
      right: 5px;
      top: 16px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' preserveAspectRatio='none' class='sf-icon-path'%3E%3Cpath d='M11 11V5C11 4.44772 11.4477 4 12 4C12.5523 4 13 4.44772 13 5V11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H13V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V13H5C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11H11Z' fill='%238d8f9a' style='height: 100%25;'%3E%3C/path%3E%3Cpath d='M11 11V5C11 4.44772 11.4477 4 12 4C12.5523 4 13 4.44772 13 5V11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H13V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V13H5C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11H11Z' fill='%238d8f9a' style='height: 100%25;'%3E%3C/path%3E%3C/svg%3E");
      width: 16px;
      height: 16px;
    }
    &.active {
      &::after {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' preserveAspectRatio='none' class='sf-icon-path'%3E%3Cpath d='M5 13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z' fill='%238d8f9a' style='height: 100%25;'%3E%3C/path%3E%3C/svg%3E");
      }
    }
  }
  .toggle-answer {
    font-size: var(--font-size--sm);
    line-height: 1.4;
    height: auto;
    max-height: 0;
    overflow: hidden;
    transform: translateY(0);
    transition: all 180ms ease-in-out 0ms;
    padding: 0;
    opacity: 0;
    margin: 0;
  }
  .toggle-question.active + .toggle-answer {
    max-height: 1000px;
    transition: all 200ms ease-in-out 0ms;
    opacity: 1;
    padding: 14px 0;
  }
}
</style>
