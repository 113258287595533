import { mapActions } from 'vuex'
import { getProductPrice } from '~/theme/helpers';
import { formatProductLink } from '@vue-storefront/core/modules/url/helpers'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import routes from '~/theme/router/index.js'

export default {
  methods: {
    ...mapActions('klaviyo', {
      reportProductPageVisitToKlaviyo: 'reportProductPageVisit',
      initKlaviyo: 'init',
      setCustomerEmailForKlaviyo: 'setCustomerEmail',
      reportAddToCartToKlaviyo: 'reportAddToCart',
      reportStartedCheckoutToKlaviyo: 'reportStartedCheckout'
    }),
    initTrackingScripts () {
      this.initKlaviyo()
    },
    setCustomerEmailAsTrackingIndentifier ({ email }) {
      this.setCustomerEmailForKlaviyo({ email })
    },
    handleProductPageView (product) {
      const price = getProductPrice(product, {}, true)
      const link = formatProductLink(product, currentStoreView().storeCode)
      this.reportProductPageVisitToKlaviyo({ product, price, link })
    },
    handleCheckoutStarted ({ totalPrice, products, cartToken }) {
      this.reportStartedCheckoutToKlaviyo({ totalPrice, products, cartToken, checkoutUrl: 'checkout' })
    },
    handleAddingToCart ({ cartProducts, totalPrice, addedProduct }) {
      this.reportAddToCartToKlaviyo({ cartProducts, totalPrice, addedProduct, checkoutUrl: 'checkout' })
    }
  }
}
