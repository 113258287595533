import config from 'config';
import { mapGetters } from 'vuex';
import { Logger } from '@vue-storefront/core/lib/logger';

export default {
  data () {
    return {
      selectedPickupPoint: null
    }
  },
  computed: {
    ...mapGetters({
      pickupPoints: 'logtrade/getPickupPoints',
      selectedPickupPointFromStore: 'logtrade/getSelectedPickupPoint'
    })
  },
  methods: {
    async onShippingMethodChanged () {
      await this.fetchPickupPoints()
    },
    async fetchPickupPoints () {
      this.selectedPickupPoint = null
      if (this.shipping.shippingMethod !== config.logtrade.pickup_code) {
        return
      }
      await this.$store.dispatch('logtrade/fetchPickupPoints')
      if (!this.pickupPoints.find(p => p.agentId === this.selectedPickupPoint) || !this.selectedPickupPoint) {
        this.selectedPickupPoint = this.pickupPoints[0]?.agentId
      }
      if (!this.selectedPickupPoint) return
      /**
       * HACK: sf-component-select doesn't update selected value
       * while number of children "SfComponentSelectOption" changes
       * to be less than before (e.g. 19 pickup options vs 13 pickup options)
       */
      const pickupRefs = Array.isArray(this.$refs.pickUp) ? this.$refs.pickUp : [this.$refs.pickUp]
      pickupRefs.forEach(ref => ref?.addOptionsAndIndexes())
      // END HACK
    },
    isSelectedMethodPickup (methodCode) {
      return methodCode === config.logtrade.pickup_code && methodCode === this.shipping.shippingMethod
    },
    async onBeforeSendDataToCheckout () {
      await this.storeLogtradeMethodData()
    },
    getLogtradeMethodData () {
      const selectedShipping = this.shippingMethods.find(_ => _.method_code === this.shipping.shippingMethod)
      const selectedPoint = this.pickupPoints.find(p => p?.agentId === this.selectedPickupPointFromStore)
      const logtradeMethodData = {
        optionId: selectedPoint?.optionId || selectedShipping?.extension_attributes?.option_id,
        agentId: selectedPoint?.agentId
      }
      if (!logtradeMethodData.optionId) {
        Logger.error('Missing optionId for logtradeMethodData', 'Logtrade', { selectedShipping, selectedPoint, logtradeMethodData })()
      }
      return logtradeMethodData
    },
    async storeLogtradeMethodData () {
      try {
        const storeMethodDataResponse = await this.$store.dispatch('logtrade/storeMethodData', this.getLogtradeMethodData())
        const { status, message } = storeMethodDataResponse[0] || storeMethodDataResponse
        if (status !== 'success') {
          this.shippingError = message || this.$t('Cannot save logtrade shipping method data')
        }
      } catch (err) {
        this.shippingError = err.message || err || this.$t('Cannot save logtrade shipping method data')
      }
    }
  }
}
