<template>
  <div class="m-price-summary" :class="{ 'is-disabled': isLoading }">
    <SfProperty
      v-show="prices.shipping_incl_tax"
      :name="$t('Shipping')"
      :value="prices.shipping_incl_tax | price"
      class="sf-property--full-width sf-property--large property"
    />
    <template v-if="isCouponCode">
      <SfProperty
        :name="$t('Special Discount')"
        :value="prices.discount | price"
        class="sf-property--full-width sf-property--large property"
      />
      <div class="promo-code">
        <SfButton
          class="promo-code__remove-button"
          :disabled="isLoadingCoupon"
          @click="removeCoupon"
        >
          {{ $t("Delete discount code") }}
        </SfButton>
      </div>
    </template>
    <SfProperty
      :name="$t('Total Discount')"
      v-if="showTotalDiscount"
      :value="totalDiscount | price"
      class="sf-property--full-width sf-property--large property"
      style="--property-name-font-weight: var(--font-weight--semibold);"
    />
    <SfProperty
      :name="$t('Total')"
      :value="prices.grand_total | price"
      class="sf-property--full-width sf-property--large property"
      style="--property-name-font-weight: var(--font-weight--semibold);"
    />
  </div>
</template>

<script>
import { getProductPrice, formatPrice } from '~/theme/helpers';
import { mapGetters } from 'vuex';
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import SfProperty from '@storefront-ui/vue/src/components/atoms/SfProperty/SfProperty.vue';
export default {
  name: 'MPriceSummary',
  components: {
    SfProperty,
    SfButton
  },
  props: {
    letMagentoAddShippingCosts: {
      type: Boolean,
      default: false
    },
    shouldInvalidateShippingMethods: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoadingCoupon: false,
      totalDiscount: 0
    }
  },
  computed: {
    ...mapGetters({
      isLoading: 'cart/getCartProcessingStatus',
      segmentTotals: 'cart/getTotals',
      isCouponCode: 'cart/getAppliedCouponCode',
      productsInCart: 'cart/getCartItems',
      shippingMethods: 'checkout/getShippingMethods',
      shippingMethod: 'cart/getShippingMethod'
    }),
    prices () {
      const segmentPrices = this.segmentTotals.reduce((result, price) => {
        result[price.code] = price.value;
        return result;
      }, {});

      if (this.shippingMethods && this.shippingMethod && !this.letMagentoAddShippingCosts) {
        const shippingMethodDetails = this.shippingMethods.find(method =>
          method.method_code === this.shippingMethod.method_code)
        if (shippingMethodDetails) {
          const currentShippingPriceIncludedInGrandTotals =
            this.$store.state.cart.platformTotals?.shipping_incl_tax || 0
          segmentPrices.shipping_incl_tax = shippingMethodDetails.price_incl_tax
          segmentPrices.grand_total += segmentPrices.shipping_incl_tax - currentShippingPriceIncludedInGrandTotals
        }
      } else if (this.$store.state.cart.platformTotals) {
        segmentPrices.shipping_incl_tax = this.$store.state.cart.platformTotals.shipping_incl_tax
      }
      if (this.isCouponCode && !segmentPrices.discount) {
        segmentPrices.discount = 0
      }
      return segmentPrices
    },
    showTotalDiscount () {
      const isTotalDiscountGreaterThanSpecialDiscount = this.totalDiscount < (this.prices.discount || 0)
      const hasDiscount = this.totalDiscount < 0
      return hasDiscount && isTotalDiscountGreaterThanSpecialDiscount
    },
    showShippingPriceIncTax () {
      return this.showShippingPrice && this.prices.shipping_incl_tax >= 0
    },
    totalItems () {
      return this.productsInCart.reduce((result, product) => {
        return result + product.qty;
      }, 0);
    }
  },
  methods: {
    async removeCoupon () {
      this.isLoadingCoupon = true
      await this.$store.dispatch('cart/removeCoupon', { shouldAskForShippingMethods: this.shouldInvalidateShippingMethods })
      this.isLoadingCoupon = false
    },
    async updateSavedAmount () {
      const self = this;
      let isSyncNeeded = false;

      try {
        const rawPrice = this.productsInCart.reduce((sum, product) => sum + getProductPrice(product, {}, true).regular, 0)
        this.totalDiscount = Math.round((this.prices.grand_total - rawPrice - this.prices.shipping_incl_tax) * 100) / 100
      } catch (err) {
        await this.$store.dispatch('cart/synchronizeCart', { forceClientState: false }).then(() => {
          isSyncNeeded = true;
        });
      }

      if (isSyncNeeded) {
        const rawPrice = self.productsInCart.reduce((sum, product) => sum + getProductPrice(product, {}, true).regular, 0)
        this.totalDiscount = Math.round((self.prices.grand_total - rawPrice - self.prices.shipping_incl_tax) * 100) / 100
      }
    }
  },
  watch: {
    /* eslint-disable no-useless-computed-key */
    ['prices.grand_total']: {
      handler () {
      /*
        Cart items don't update at the same time as totals, so if totalDiscount would be a computed,
        the money would flicker. Instead we tie updating totalDiscount to grand total update
       */
        this.updateSavedAmount()
      },
      immediate: true,
      deep: true
    }
  }
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-price-summary {
  margin: var(--spacer-xs) 0;
  min-height: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &__loading {
    justify-content: center;
    display: flex;
  }
  &.is-disabled {
    --property-color: var(--c-text-disabled);
    --property-name-color: var(--c-text-disabled);
  }
  .property {
    --property-name-font-size: var(--font-size--base);
    margin: var(--spacer-xs) 0 0 0;
    @include for-desktop {
      margin: var(--spacer-sm) 0 0 0;
      &__total {
        padding: var(--spacer-sm) 0 0 0;
      }
    }
  }
}
.promo-code {
  margin: var(--spacer-xs) 0 var(--spacer-base) 0;
  justify-content: center;
  display: flex;
  &__remove-button {
    padding: var(--spacer-xs) var(--spacer-sm);
    font-size: var(--font-size--sm);
  }
}
</style>
