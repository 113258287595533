<template>
  <svg
    role="img"
    :width="size"
    :height="size"
    viewBox="0 0 38 38"
    class="sf-loader__spinner"
  >
    <g fill="none" fill-rule="evenodd">
      <g transform="translate(1 1)" stroke-width="2">
        <circle
          stroke-opacity=".5"
          fill="transparent"
          cx="18"
          cy="18"
          r="18"
        />
        <path d="M36 18c0-9.94-8.06-18-18-18">
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 18 18"
            to="360 18 18"
            dur="1s"
            repeatCount="indefinite"
          />
        </path>
      </g>
    </g>
  </svg>
</template>
<script>
export default {
  name: 'ASpinner',
  props: {
    size: {
      default: 26,
      type: Number
    }
  }
}
</script>
